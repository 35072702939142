<template>
    <pc-select
        v-model="inputValue"
        v-bind="$attrs"
        :items="inputItems"
        :top-level-as-optgroup="mode === 'budgetcode' && !parent"
    />
</template>

<script lang="ts">
import {Request} from "@/lib/apollo";
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

enum PCDepartmentMode {
    Department = 'department',
    Budgetcode = 'budgetcode'
}

@Component({
    inheritAttrs: false,
})
export default class PCDepartment extends Vue {
    @Prop({ default: '' })
    readonly value: string;

    @Prop({ default: PCDepartmentMode.Department })
    readonly mode: PCDepartmentMode;

    @Prop({ default: '' })
    readonly placeholder: string;

    @Prop({ default: '' })
    readonly parent: string;

    @Prop({ default: () => [] })
    readonly items: Array<any>;

    inputValue = '';

    departments: Array<any> = [];

    created () {
        this.inputValue = this.value;
        this.onItemsChange(this.items);
    }

    @Watch('items')
    onItemsChange(items: Array<any>) {
        if (items.length) {
            this.departments = items;
        } else {
            this.getData();
        }
    }

    getData() {
        let url = '/customer/sector-flat-tree';

        let params = new URLSearchParams();
        if (this.mode === PCDepartmentMode.Budgetcode) {
            params.append('budget', 'true');
        }
        if (this.mode === PCDepartmentMode.Budgetcode && this.parent) {
            params.append('parent', this.parent);
        }

        url += '?' + params.toString();

        Request.make(url, Request.GET, {}, true).then((res: any) => {
            this.departments = res;
        });
    }

    get inputItems() {
        let output: Array<any> = [];
        output.push({ label: this.placeholder, value: '' });
        for (let i in this.departments) {
            output.push({
                label: this.departments[i].name,
                value: this.departments[i].id,
                depth: this.departments[i].depth
            });
        }
        return output;
    }

    @Watch('value')
    onValueChange(val: string) {
        this.inputValue = val;
    }

    @Watch('inputValue')
    onInputChange(val: string) {
        this.$emit('input', val);
        this.$emit('change', val);
    }

    @Watch('parent')
    onParentChange() {
        this.getData();
    }
}
</script>
