import Request from './request';

export default class ResourceCollection {
    private _baseIri: string;
    private _resource: any;
    private _current: any;
    private _currentPage = 1;
    private _loading = false;

    constructor(resource: any) {
        if (resource !== undefined && resource !== null) {
            this._baseIri = resource['@id'];
            this._resource = resource;
            this._current = resource['hydra:member'];
        }
    }

    public get items() {
        return this._current;
    }

    public page(previous = false, add = false) {
        this._loading = true;

        let url = process.env.VUE_APP_API_HOST;
        if (previous) {
            url += this._resource['hydra:view']['hydra:previous'];
        } else {
            url += this._resource['hydra:view']['hydra:next'];
        }

        return Request.make(url, Request.GET, {}, true).then(res => {
            this._resource = res;
            if (add) {
                this._current = [
                    ...this._current,
                    ...res['hydra:member'],
                ];
            } else {
                this._current = res['hydra:member'];
            }
            this._loading = false;
        });
    }

    public prev(add = false) {
        if (this._currentPage > 1) {
            this._currentPage--;
        }

        return this.page(true, add);
    }

    public next(add = false) {
        if (this._currentPage < this.totalPages) {
            this._currentPage++;
        }

        return this.page(false, add);
    }

    public get hasPrevPage() {
        return this._currentPage > 1;
    }

    public get hasNextPage() {
        return this._currentPage < this.totalPages;
    }

    public get currentPage(): number {
        return this._currentPage;
    }

    public get totalPages(): number {
        return Math.ceil(this.total / 20);
    }

    public get total(): number {
        return this._resource['hydra:totalItems'];
    }

    public get isLoading(): boolean {
        return this._loading;
    }

    private _getFirstPage() {
        return this._resource['hydra:view']['hydra:first'];
    }

    private _getLastPage() {
        return this._resource['hydra:view']['hydra:last'];
    }
}
