<template>
    <div class="mb-4">
        <pc-select
            v-model="inputValue"
            v-bind="$attrs"
            :items="items"
        />
        <template v-if="inputValue && currentItem">
            <div class="address_preview">
                <span class="material-symbols-outlined" style="vertical-align: middle">location_on</span>
                {{ currentItem.fullAddress }}
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import {Request, ResourceCollection} from "@/lib/apollo";
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
    inheritAttrs: false,
})
export default class PCAddress extends Vue {
    @Prop({ default: '' })
    readonly value: string;

    inputValue = '';

    addresses: Array<any> = [];

    created () {
        this.inputValue = this.value;

        this.getData();

        this.$root.$on('pc-address-refresh-items', (addressId: string) => {
            this.getData().then(() => {
                if (addressId) {
                    this.inputValue = addressId;
                }
            });
        });
    }

    getData() {
        return Request.make('/api/address_templates?_order%5Blabel%5D=asc', Request.GET, {}, true).then((res: any) => {
            const result = new ResourceCollection(res);

            this.addresses = result.items;
        });
    }

    get items() {
        const output = [];
        for (let i in this.addresses) {
            output.push({ value: this.addresses[i].id, label: this.addresses[i].label });
        }
        return output;
    }

    get currentItem() {
        for (let i in this.addresses) {
            if (this.addresses[i].id === this.inputValue) {
                return this.addresses[i];
            }
        }
        return null;
    }

    @Watch('value')
    onValueChange(val: string) {
        this.inputValue = val;
    }

    @Watch('inputValue')
    onInputChange(val: string) {
        this.$emit('input', val);
        this.$emit('change', val);
    }
}
</script>

<style lang="scss">
    .address_preview {
        background-color: #F5F5F5;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 600;
        color: #878787;
        line-height: 26px;
        padding: 9px 12px;
    }
</style>
