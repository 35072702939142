<template>
    <div class="table-responsive">
        <table class="table" :class="{ 'table-hover': hover }" v-bind="$attrs">
            <thead>
                <slot name="header" :headers="headers">
                    <tr>
                        <template v-for="header in headers">
                            <slot :name="'header:' + header.key" :header="header">
                                <th :key="header.key" :class="{ 'text-end': header.align === 'right', 'clickable': header.sortable, 'text-nowrap': true, 'shrink': header.shrink }" @click="setSort(header)">
                                    {{ $t(header.label) }}
                                    <template v-if="header.sortable && pagination.order === header.value">
                                        <span class="material-symbols-outlined" style="vertical-align: middle" v-html="pagination.descending ? 'arrow_drop_down' : 'arrow_drop_up'"></span>
                                    </template>
                                </th>
                            </slot>
                        </template>
                    </tr>
                </slot>
            </thead>
            <tbody>
                <slot name="rows" :items="items">
                    <template v-for="(item, idx) in items">
                        <slot name="item" :item="item" :idx="idx">
                            <tr :key="idx" @click="$emit('row-click', item)" :class="{ 'clickable': clickable }">
                                <template v-for="(header, hidx) in headers">
                                    <slot :name="'item:' + header.key" :item="item">
                                        <td :key="hidx" :class="{ 'text-end': header.align === 'right', 'text-nowrap': true }">{{ item[header.value] }}</td>
                                    </slot>
                                </template>
                            </tr>
                        </slot>
                    </template>
                </slot>
                <template v-if="loading">
                    <template v-for="i in rowsToLoad">
                        <tr :key="'ld' + i">
                            <template v-for="j in headers.length">
                                <td :key="'ldc' + j" :class="{ 'placeholder-glow': true, 'text-end': headers[j-1].align === 'right' }">
                                    <span :class="'placeholder col-' + String(Math.floor(Math.random() * (7 - 4) + 4))"></span>
                                </td>
                            </template>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

export interface HeaderItem {
    label: string;
    key: string;
    value: string;
    sortable: boolean;
    shrink: boolean;
}

export interface Pagination {
    total: number;
    page: number;
    per_page: number;
    order: string;
    descending: boolean;
}

@Component({
    inheritAttrs: false
})
export default class PCTable extends Vue {
    @Prop({ default: () => [] })
    readonly headers: Array<HeaderItem>;

    @Prop({ default: () => [] })
    readonly items: Array<any>;

    @Prop({ default: false })
    readonly loading: boolean;

    @Prop({ default: false })
    readonly clickable: boolean;

    @Prop({ default: false })
    readonly hover: boolean;

    @Prop({ default: () => { return {} } })
    readonly pagination: Pagination;

    get rowsToLoad() {
        if (!this.pagination.total) {
            return this.pagination.per_page;
        }
        let amount = this.pagination.total - this.items.length;
        return amount > this.pagination.per_page ? this.pagination.per_page : amount;
    }

    setSort(header: HeaderItem) {
        if (!header.sortable) {
            return;
        }

        let newPagination: Pagination = {
            total: this.pagination.total,
            page: this.pagination.page,
            per_page: this.pagination.per_page,
            order: this.pagination.order,
            descending: this.pagination.descending,
        };

        if (newPagination.order !== header.value) {
            newPagination.order = header.value;
        } else {
            newPagination.descending = !newPagination.descending;
        }

        this.$emit('update:pagination', newPagination);
    }
}
</script>
