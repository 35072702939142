import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/contact',
        name: 'contact_page',
        component: () => import(/* webpackChunkName: "contact_page" */ '@/views/contact/contact.vue'),
        meta: {
            title: 'Contact with Postma & Cohen'
        }
    },
]
export default routes;
