import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/circulation-lists',
        props: true,
        component: () => import(/* webpackChunkName: "circulation_list" */ '@/views/circulation-list/view.vue'),
        children: [
            {
                name: 'circulation_list_overview',
                path: '',
                component: () => import(/* webpackChunkName: "circulation_list_overview" */ '@/views/circulation-list/tabs/list.vue'),
                meta: { title: 'Circulation lists' },
            },
            {
                name: 'circulation_list_settings',
                path: 'settings',
                component: () => import(/* webpackChunkName: "circulation_list_settings" */ '@/views/circulation-list/tabs/settings.vue'),
                meta: { title: 'Circulation lists' },
            },
        ],
    },
]
export default routes;
