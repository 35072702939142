<template>
    <div>
        <h4 class="mb-5">{{ $t('Reset password') }}</h4>
        <pc-form @submit="updatePassword()">
            <pc-input type="password" label="New password" required="required" v-model="formData.password" v-bind="$errorState(formResult, 'plainPassword.first')" />
            <pc-input type="password" label="Repeat new password" required="required" v-model="formData.passwordRepeat" v-bind="$errorState(formResult, 'plainPassword.second')" />
            <pc-btn type="submit" class="btn-primary">{{ $t('Save') }}</pc-btn>
        </pc-form>
    </div>
</template>

<script lang="ts">
import Request from "@/lib/apollo/request";
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class ResetPassword extends Vue {
    @Prop({ default: '' })
    readonly id: string;

    formData: any = {
        password: '',
        passwordRepeat: '',
    }

    formResult: any = {};

    updatePassword() {
        let promise: Promise<any>;

        if (this.id) {
            promise = this.updatePasswordFromID();
        } else {
            promise = this.updatePasswordAfterLogin();
        }

        promise.then(() => {
            if (this.formResult.status === 'OK') {
                this.formData.password = '';
                this.formData.passwordRepeat = '';
                this.formResult = {};
                this.$router.push({ name: 'home' });
            } else {
                if (this.formResult.msg && this.formResult.msg['_global'] && this.formResult.msg['_global'].length) {
                    this.$flashMessage(this.formResult.msg['_global'][0], 'danger');
                }
            }
        });
    }

    updatePasswordFromID(): Promise<any> {
        const formData = new FormData();
        formData.append('password_reset[plainPassword][first]', this.formData.password);
        formData.append('password_reset[plainPassword][second]', this.formData.passwordRepeat);

        return Request.make('/gebruiker/wachtwoord-reset/' + encodeURIComponent(this.id), Request.POST, formData).then((res: any) => {
            this.formResult = res;
            if (res.status === 'OK') {
                this.$flashMessage('Password changed, you can now login', 'success');
            }
        });
    }

    updatePasswordAfterLogin(): Promise<any> {
        const formData = new FormData();
        formData.append('password_change[plainPassword][first]', this.formData.password);
        formData.append('password_change[plainPassword][second]', this.formData.passwordRepeat);

        return Request.make('/gebruiker/wachtwoord', Request.POST, formData).then((res: any) => {
            this.formResult = res;
            if (res.status === 'OK') {
                this.$flashMessage('Saved', 'success');
                this.$store.state.user.passwordResetOnLogin = false;
            }
        });
    }
}
</script>
