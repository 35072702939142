<template>
    <div id="app">
        <component v-if="$store.state.initialized" :is="getLayout()">
            <template v-if="!this.$store.state.authenticated">
                <template v-if="isRouteAllowed()">
                    <router-view/>
                </template>
                <template v-else>
                    <login/>
                </template>
            </template>
            <template v-else>
                <template v-if="$store.state.user.passwordResetOnLogin">
                    <reset-password />
                </template>
                <template v-else-if="$store.state.user.roles.ROLE_ADMIN">
                    <span v-html="$t('It seems you are logged in as an administrator. What do you wish to do?')"></span><br/><br/>
                    <ul>
                        <li><a :href="adminUrl">{{ $t('Visit the admin website') }}</a></li>
                        <li><a href="javascript:" @click="$logout()">{{ $t('Logout') }}</a></li>
                    </ul>
                </template>
                <template v-else>
                    <router-view/>
                </template>
            </template>
        </component>
        <component v-else :is="getLayout()">
            {{ $t('Loading') }}
        </component>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import DefaultLayout from '@/layouts/default.vue';
import LoginLayout from '@/layouts/login.vue';
import Login from "@/views/security/login.vue";
import ResetPassword from "@/views/security/reset-password.vue";

@Component({
    components: {
        Login,
        ResetPassword
    }
})
export default class App extends Vue {
    getLayout () {
        if (this.$route.meta && this.$route.meta.layout) {
            return this.$route.meta.layout;
        }

        if (this.$store.state.user.passwordResetOnLogin || (this.$store.state.authenticated && this.$store.state.user.roles.ROLE_ADMIN)) {
            return LoginLayout;
        } else if (this.$store.state.authenticated) {
            return DefaultLayout;
        }

        return LoginLayout;
    }

    isRouteAllowed(): boolean {
        const allowed = ['forgot_password', 'reset_password', 'oci_login', 'twofa_login'];
        return allowed.indexOf(this.$router.currentRoute.name as string) > -1;
    }

    get adminUrl() {
        if (window.location.hostname === 'klanten.postma-cohen.nl') {
            return 'https://admin.postma-cohen.nl/';
        } else if (window.location.hostname === 'staging-klanten.postma-cohen.nl') {
            return 'https://staging-admin.postma-cohen.nl/';
        }
        return 'javascript:';
    }
}
</script>

<style lang="scss">
@import "sass/main";
</style>
