<template>
    <div>
        <pc-modal v-model="dialog" width="1250">
            <template v-slot:label>
                <template v-if="exists">{{ $t('Edit manual') }}</template>
                <template v-else>{{ $t('Add manual') }}</template>
            </template>
            <template v-slot:body>
                <pc-form @submit="submit" id="manualForm">
                    <pc-select required="required" label="Language" :items="languages" v-model="selectedLang" />

                    <pc-select required="required" label="Name" :items="routes" v-model="selectedRoute">
                        <template v-slot:label="{ item }">
                            {{ item.label }} - {{ item.path }}
                        </template>
                    </pc-select>

                    <div v-if="selectedLang && selectedRoute">
                        <pc-input :label="$t('Title')" v-model="formData.title" v-bind="{ error: formHandler.checkError('title'), 'error-messages': formHandler.getErrors('title') }" />

                        <label class="form-label">{{ $t('Content') }}:</label>
                        <wysiwyg v-model="formData.content" v-bind="{ error: formHandler.checkError('content'), 'error-messages': formHandler.getErrors('content') }" />
                    </div>
                </pc-form>
            </template>
            <template v-slot:footer>
                <div class="row w-100">
                    <div class="col ps-0">
                        <pc-btn v-if="exists && id" type="button" class="btn-outline-danger" @click="manualRemoveModal.enabled = true">{{ $t('Delete') }}</pc-btn>
                    </div>
                    <div class="col-auto pe-0">
                        <pc-btn type="button" class="btn-outline-primary" @click="closeDialog()">{{ $t('Close') }}</pc-btn>
                        <pc-btn type="submit" class="btn-primary ms-3" form="manualForm" :disabled="saving" v-html="exists ? $t('Save') : $t('Add')" />
                    </div>
                </div>
            </template>
        </pc-modal>
        <pc-confirm v-model="manualRemoveModal.enabled" @confirmed="deleteManual()" />
    </div>
</template>

<script lang="ts" type="typescript">
import {Component, Prop, Vue} from 'vue-property-decorator';
import Apollo, { ResourceCollection } from '@/lib/apollo';
import FormHandler, { FormHandlerResultObject } from '@/lib/form/form';
import routes from '@/router';
import {RouteConfig} from "vue-router";
import wysiwyg from 'vue-wysiwyg';

Vue.use(wysiwyg, {
    image: {
        uploadURL: `${process.env.VUE_APP_API_HOST}/upload/files/store`,
    }
});

interface SendObject {
    // Entity velden hieronder toevoegen
    title: string;
    content: string;
    name: string;
    language: string;
}

@Component
export default class ManualDialog extends Vue {
    @Prop({ default: '' })
    routeName: string;

    @Prop({ default: false })
    isLegacy: boolean;

    id = '';
    languages: any[] = [];
    selectedLang = '';
    selectedRoute = '';
    saving = false;
    dialog = false;
    entries: any[] = [];
    exists = false;

    formData: SendObject = {
        title: '',
        content: '',
        name: '',
        language: ''
    };

    routes: any[] = [];

    formHandler = new FormHandler();

    tmp = 0;

    manualRemoveModal: any = {
        enabled: false,
    };

    created() {
        this.$root.$off('manual-dialog-enable');
        this.$root.$on('manual-dialog-enable', (data: any) => {
            this.formData.name = data.routeName;
            this.openDialog();
        });
    }

    openDialog() {
        this.selectedLang = this.$i18n.locale;
        this.selectedRoute = this.$route.name ?? '';

        const languages: string[] = ['nl', 'en'];
        this.languages = [];
        for (let language in languages) {
            this.languages.push({label: languages[language].toUpperCase(), value: languages[language]})
        }

        this.routes = [];
        this.loadRoutes(routes.options.routes as Array<RouteConfig>, undefined);

        this.loadEntry().then(() => {
            this.$watch('selectedLang', this.loadEntry);
            this.$watch('selectedRoute', this.loadEntry);
            this.dialog = true;
        });
    }

    closeDialog() {
        this.dialog = false;
    }

    loadRoutes(items: Array<RouteConfig|any>, parent: any) {
        let i: any;
        for (i in items) {
            if (!Object.prototype.hasOwnProperty.call(items, i)) {
                continue;
            }
            if (Object.prototype.hasOwnProperty.call(items[i], 'name')) {
                let name = items[i]['name'];
                let path = (typeof parent !== 'undefined' ? parent['path'] + ' → ' : '') + items[i]['path'];

                let title = undefined;
                if (Object.prototype.hasOwnProperty.call(items[i], 'meta')) {
                    title = (items[i]['meta'] !== undefined && items[i]['meta']['title'] ? items[i]['meta']['title'] : '');
                    title = String(this.$t(title));
                }

                let text = (title ? title : name);

                this.routes.push({'label': text, 'value': name, 'path': path});
            }
            if (Object.prototype.hasOwnProperty.call(items[i], 'children')) {
                this.loadRoutes(items[i]['children'] as Array<RouteConfig>, items[i]);
            }
        }
    }

    setEmpty() {
        this.id = '';
        this.exists = false;
        this.formData = {
            title: '',
            content: '',
            name: this.selectedRoute,
            language: ''
        };

        return new Promise<void>((resolve) => { resolve() });
    }

    async loadEntry(): Promise<void> {
        if (!this.selectedLang || !this.selectedRoute) {
            return this.setEmpty();
        }
        let res = await Apollo.get('manual_entries').all({
            language: this.selectedLang,
            name: this.selectedRoute
        });
        if (res.total) {
            this.id = res.items[0].id;
            this.exists = true;
            this.formData.title = res.items[0].title;
            this.formData.content = res.items[0].content;
            this.formData.name = res.items[0].name;
            this.formData.language = res.items[0].language;
        } else {
            await this.setEmpty();
        }
    }

    submit(): boolean {
        this.saving = true;
        let request;

        this.formData.language = this.selectedLang;
        if (this.id) {
            request = Apollo.get('manual_entries').update(this.id, this.formData);
        } else {
            request = Apollo.get('manual_entries').create(this.formData);
        }

        request.then((res: FormHandlerResultObject|any) => {
            this.formHandler.setResult(res);

            this.$flashMessage('Saved', 'success');
            this.saving = false;
            this.closeDialog();
            this.$router.go(0);
        }).catch((res: FormHandlerResultObject|any) => {
            this.formHandler.setResult(res);
            this.saving = false;
            let error: string = this.formHandler.getGlobalError();
            if (error) {
                this.$flashMessage(error, 'error');
            }
        });

        return false;
    }

    deleteManual() {
        this.saving = true;
        Apollo.get('manual_entries').delete(this.id).then(() => {
            this.$flashMessage('Saved', 'success');

            this.saving = false;
            this.closeDialog();
            this.$router.go(0);
        });
    }
}

</script>

<style lang="css">
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>