import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        name: 'news_list',
        path: '/news',
        component: () => import(/* webpackChunkName: "news_list" */ '@/views/news-list/news-list.vue'),
        meta: {
            title: 'News archive'
        }
    },
]
export default routes;
