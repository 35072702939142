import { Roles } from '@/entity/roles'

class User {
    public authenticated = false;
    public groupsVisible = [];
    public id = '';
    public isAllowedToSwitch = false;
    public isReadOnly = false;
    public isSwitched = false;
    public twoFaInProgress = false;
    public locale = 'nl';
    public loginCount = 0;
    public name = '';
    public ociHookUrl = '';
    public cxmlBuyerCookie = '';
    public organizationLayers = [];
    public passwordResetOnLogin = false;
    public personEmail = '';
    public personID = '';
    public personPhone = '';
    public referenceGeneratorEnabled = '';
    public relationID = '';
    public relationName = '';
    public relationNumber = '';
    public roles: Roles = {} as Roles;
    public username = '';
    public settings: Settings = {
        defaultPath: '/',
        template: 'default',
        hideDashboard: false,
        orderStop: null
    };
}

interface Settings {
    defaultPath: string;
    template: string;
    hideDashboard: boolean;
    orderStop: {startDate: string, endDate: string}|null
}

export default User
