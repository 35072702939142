import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        name: 'forgot_password',
        path: '/wachtwoord-vergeten',
        component: () => import(/* webpackChunkName: "forgot_password" */ '@/views/security/forgot-password.vue'),
        meta: {
            title: 'Forgot password'
        }
    },
    {
        name: 'reset_password',
        path: '/wachtwoord-reset/:id',
        props: true,
        component: () => import(/* webpackChunkName: "reset_password" */ '@/views/security/reset-password.vue'),
        meta: {
            title: 'Reset password'
        }
    },
    {
        name: "oci_login",
        path: "/oci/login/:token?",
        props: true,
        component: () => import(/* webpackChunkName: "oci_login" */ '@/views/security/oci-login.vue'),
        meta: {
            title: 'Login'
        }
    },
    {
        name: "twofa_login",
        path: "/2fa",
        component: () => import(/* webpackChunkName: "2fa_login" */ '@/views/security/2fa.vue'),
        meta: {
            title: 'Login'
        }
    }
]
export default routes;
