import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/cart',
        name: 'cart_index',
        component: () => import(/* webpackChunkName: "cart_index" */ '@/views/cart/cart.vue'),
        meta: {
            title: 'Cart'
        }
    },
]
export default routes;
