import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/communication',
        name: 'contact_list',
        component: () => import(/* webpackChunkName: "contact_list" */ '@/views/contact-list/contact-list.vue'),
        meta: {
            title: 'Communication'
        }
    },
]
export default routes;
