import Vue from 'vue'
import Vuex from 'vuex'
import User from "@/entity/user";

Vue.use(Vuex)

function defaultState() {
  return {
    initialized: false,
    authenticated: false,
    user: new User(),
    locale: 'nl',
    organizationName: '',
    flashMessage: '',
    flashMessageOptions: {},
    manualExists: false,
  }
}

export default new Vuex.Store({
  state: defaultState(),
  getters: {
    hasFlash: (state) => {
      return state.flashMessage.length >= 1;
    },
  },
  mutations: {
    reset(state) {
      state = defaultState();
    },
    initialized(state, init) {
      state.initialized = init;
    },
    authenticated(state, auth) {
      state.authenticated = auth;
    },
    user(state, value) {
      state.user = value;
    },
    organizationName(state, value) {
      state.organizationName = value;
    },
    resetAuth(state) {
      state.authenticated = false;
      state.user = new User();
      state.organizationName = '';
    },
    flashMessage(state, msg) {
      let message = '';

      if (typeof msg === 'string') {
        message = msg;
      } else {
        message = msg.msg;
      }

      state.flashMessage = message;

      if (msg.options) {
        state.flashMessageOptions = msg.options;
      } else {
        state.flashMessageOptions = {
          success: true,
        };
      }

      if (msg.options?.removeAutomatically) {
        setTimeout(() => {
          state.flashMessage = "";
        }, 6000);
      }

    },
    locale(state, value) {
      state.locale = value;
    },
    manualExists(state, value: boolean) {
      state.manualExists = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
