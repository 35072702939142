import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'whatwg-fetch'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import Apollo from "@/lib/apollo"
import AuthenticationPlugin from "@/plugins/authentication"
import FlashMessagesPlugin from "@/plugins/flash-messages"
import FormPlugin from "@/plugins/form";
import CartPlugin from "@/plugins/cart"
import OrganizationLayerPlugin from "@/plugins/organization-layer"
import CookiePlugin from "@/plugins/cookie"

Vue.config.productionTip = false

// Global components
import PCInput from './components/form/PCInput.vue'
import PCNumber from './components/form/PCNumber.vue'
import PCBtn from './components/form/PCBtn.vue'
import PCSelect from './components/form/PCSelect.vue'
import PCCheckBox from './components/form/PCCheckBox.vue'
import PCRadio from './components/form/PCRadio.vue'
import PCCountry from './components/form/PCCountry.vue'
import PCLocale from './components/form/PCLocale.vue'
import PCAddress from './components/form/PCAddress.vue'
import PCDepartment from './components/form/PCDepartment.vue'
import PCForm from './components/form/PCForm.vue'
import PCOffCanvas from './components/PCOffCanvas.vue'
import PCModal from './components/PCModal.vue'
import PCAccordion from './components/PCAccordion.vue'
import PCTable from './components/PCTable.vue'
import PCAddToCartButton from './components/cart/PCAddToCartButton.vue'
import PCConfirm from "@/components/PCConfirm.vue";
Vue.component('pc-number', PCNumber);
Vue.component('pc-input', PCInput);
Vue.component('pc-btn', PCBtn);
Vue.component('pc-select', PCSelect);
Vue.component('pc-checkbox', PCCheckBox);
Vue.component('pc-radio', PCRadio);
Vue.component('pc-form', PCForm);
Vue.component('pc-offcanvas', PCOffCanvas);
Vue.component('pc-modal', PCModal);
Vue.component('pc-accordion', PCAccordion);
Vue.component('pc-table', PCTable);
Vue.component('pc-add-cart-btn', PCAddToCartButton);
Vue.component('pc-country', PCCountry);
Vue.component('pc-address', PCAddress);
Vue.component('pc-department', PCDepartment);
Vue.component('pc-confirm', PCConfirm);
Vue.component('pc-locale', PCLocale);

// Filters
Vue.filter('dformat', function (value: string) {
    if (!value) {
        return '-';
    }
    return (new Date(Date.parse(value)))
        .toLocaleString(['nl-NL'], {
            dateStyle: 'short',
        });
});
Vue.filter('dtformat', function (value: string) {
    if (!value) {
        return '-';
    }
    return (new Date(Date.parse(value)))
        .toLocaleString(['nl-NL'], {
            dateStyle: 'short',
            timeStyle: 'short'
        });
});
Vue.filter('nl2br', function (value: string) {
    return value.replaceAll("\n", '<br />');
});

// Plugins
Vue.use(AuthenticationPlugin);
Vue.use(FlashMessagesPlugin);
Vue.use(CartPlugin);
Vue.use(FormPlugin);
Vue.use(OrganizationLayerPlugin);
Vue.use(CookiePlugin);

// Init
new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    beforeCreate() {
        Apollo.init().then(() => {
            this.$initAuth().then(() => {
                this.$store.commit('initialized', true);
            });
        });
    }
}).$mount('#app')
