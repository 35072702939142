import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/orders/all',
        name: 'orders_all',
        component: () => import(/* webpackChunkName: "orders_all" */ '@/views/order-list/all.vue'),
        meta: {
            title: 'My products'
        }
    },
    {
        path: '/orders/subscription',
        name: 'orders_subscription',
        component: () => import(/* webpackChunkName: "orders_subscription" */ '@/views/order-list/subscription.vue'),
        meta: {
            title: 'My subscriptions'
        }
    },
    {
        path: '/orders/membership',
        name: 'orders_membership',
        component: () => import(/* webpackChunkName: "orders_membership" */ '@/views/order-list/membership.vue'),
        meta: {
            title: 'My memberships'
        }
    },
    {
        path: '/orders/book',
        name: 'orders_book',
        component: () => import(/* webpackChunkName: "orders_book" */ '@/views/order-list/book.vue'),
        meta: {
            title: 'My books'
        }
    },
    {
        path: '/orders/by-product/:id',
        name: 'orders_by_product',
        component: () => import(/* webpackChunkName: "orders_by_product" */ '@/views/order-list/by-product.vue'),
        meta: {
            title: 'Previous orders with product'
        },
        props: true,
    },
]
export default routes;
