import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/order/:id',
        props: true,
        component: () => import(/* webpackChunkName: "order_view" */ '@/views/order-view/view.vue'),
        //meta: { title: 'Details' },
        children: [
            {
                name: 'order_view_general',
                path: '',
                component: () => import(/* webpackChunkName: "order_view_general" */ '@/views/order-view/tabs/general.vue'),
                //meta: { title: 'General' },
            },
            {
                name: 'order_view_user',
                path: 'user',
                component: () => import(/* webpackChunkName: "order_view_user" */ '@/views/order-view/tabs/user.vue'),
                //meta: { title: 'User' },
            },
            {
                name: 'order_view_invoice_data',
                path: 'invoice-data',
                component: () => import(/* webpackChunkName: "order_view_invoice_data" */ '@/views/order-view/tabs/invoice_data.vue'),
                //meta: { title: 'Invoice data' },
            },
            {
                name: 'order_view_invoice_history',
                path: 'invoice-history',
                component: () => import(/* webpackChunkName: "order_view_invoice_history" */ '@/views/order-view/tabs/invoice_history.vue'),
                //meta: { title: 'Invoice history' },
            },
            {
                name: 'order_view_receipt_registration',
                path: 'receipt-registration',
                component: () => import(/* webpackChunkName: "order_view_receipt_registration" */ '@/views/order-view/tabs/receipt_registration.vue'),
                //meta: { title: 'Receipt Registration' },
            },
            {
                name: 'order_view_contact',
                path: 'contact',
                component: () => import(/* webpackChunkName: "order_view_contact" */ '@/views/order-view/tabs/contact.vue'),
                //meta: { title: 'Contact' },
            },
            {
                name: 'order_view_circulation',
                path: 'circulation',
                component: () => import(/* webpackChunkName: "order_view_circulation" */ '@/views/order-view/tabs/circulation.vue'),
                //meta: { title: 'Contact' },
            },
        ],
    },
]
export default routes;
