import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        name: 'address_list',
        path: '/addresses',
        component: () => import(/* webpackChunkName: "address_list" */ '@/views/address-list/address-list.vue'),
        meta: {
            title: 'Addresses'
        }
    },
]
export default routes;
