import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        name: 'my_settings',
        path: '/my-settings',
        component: () => import(/* webpackChunkName: "my_settings" */ '@/views/my-settings/view.vue'),
        children: [
            {
                name: 'my_settings_password',
                path: 'password',
                component: () => import(/* webpackChunkName: "my_settings_password" */ '@/views/my-settings/tabs/change-password.vue'),
                meta: {
                    title: 'Change password'
                }
            },
            {
                name: 'my_settings_twofa',
                path: 'twofa',
                component: () => import(/* webpackChunkName: "my_settings_twofa" */ '@/views/my-settings/tabs/twofa.vue'),
                meta: {
                    title: 'Two-factor authentication'
                }
            },
            {
                name: 'my_settings_nur_notification',
                path: 'nur-notification',
                component: () => import(/* webpackChunkName: "my_settings_nur_notification" */ '@/views/my-settings/tabs/nur-notification.vue'),
                meta: {
                    title: 'New book alerts'
                }
            },
        ],
        meta: {
            title: 'My settings'
        }
    },
]
export default routes;
