import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/catalog/subscriptions/:query?',
        props: true,
        name: 'catalog_subscription',
        component: () => import(/* webpackChunkName: "catalog_subscription" */ '@/views/catalog/subscription.vue'),
        meta: {
            title: 'Subscriptions'
        }
    },
    {
        path: '/catalog/books/:query?',
        props: true,
        name: 'catalog_book',
        component: () => import(/* webpackChunkName: "catalog_book" */ '@/views/catalog/book.vue'),
        meta: {
            title: 'Books'
        }
    },
    {
        path: '/catalog/new/:date?',
        props: true,
        name: 'catalog_new',
        component: () => import(/* webpackChunkName: "catalog_new" */ '@/views/catalog/new.vue'),
        meta: {
            title: 'New books'
        }
    },
]
export default routes;
