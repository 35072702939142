import '@/lib/hooks';
import Request from '@/lib/apollo/request';
import User from "@/entity/user";
//import checkPasswordResetOnLogin from "@/reset-password-on-login";

declare module 'vue/types/vue' {
    interface Vue {
        credentials: object;

        heartbeatInterval: number;

        $initAuth(res?: object): Promise<void>;

        $login(credentials: object): Promise<void>;

        $twoFaLogin(credentials: object): Promise<void>;

        $pushRedirect(): void;

        $loginInit(res: User, pushRedirect?: boolean): void;

        $impersonate(username: string): void;

        $toPrevious(): void;

        $logout(): Promise<void>;

        $loginReset(): void;

        $heartbeat(): void;

        //$checkPasswordResetOnLogin(fromError?: boolean);
    }
}

export default class AuthenticationPlugin {
    public static install(Vue: any, options: any) {
        Vue.prototype.$credentials = {
            username: '',
            password: '',
            _remember_me: false
        };

        Vue.prototype.$heartbeatInterval = null;

        Vue.prototype.$initAuth = function (): Promise<void> {
            this.$store.commit('resetAuth');

            const performInit = (result: any) => {
                if (result) {
                    this.$loginInit(result);
                } else {
                    this.$loginReset();
                    if (this.$router.currentRoute.name !== 'oci_login'
                        && this.$router.currentRoute.name !== 'cxml_login'
                        && this.$router.currentRoute.name !== 'reset-password'
                        && this.$router.currentRoute.name !== 'public_overview'
                        && this.$router.currentRoute.name !== 'home') {
                        this.$router.push('/');
                    }
                }
            };

            return Request.make('/login', Request.GET).then((reqRes: User) => {
                performInit(reqRes);
            });
        };

        Vue.prototype.$displayErrorMessage = function (res: any) {
            if (res.msg === 'BadCredentials') {
                this.$flashMessage('LoginFailed', 'warning');
            } else if (res.msg === 'AccountDisabled') {
                this.$flashMessage('LoginFailedAccountDisabled', 'warning');
            } else if (res.msg === 'IPUnauthorized') {
                this.$flashMessage('LoginFailedIPUnauthorized', 'warning');
            } else {
                this.$flashMessage('Error', 'danger');
            }
        }

        Vue.prototype.$login = function (credentials: any) {
            return Request.make('/login_check', Request.POST, credentials).then((res: User) => {
                if (res.twoFaInProgress) {
                    this.$router.push({name: 'twofa_login'});
                } else {
                    this.$loginInit(res, true);
                }
            }).catch((res) => {
                this.$displayErrorMessage(res);
            });
        };

        Vue.prototype.$twoFaLogin = function (credentials: any) {
            const formData = new FormData();
            formData.append('_auth_code', credentials.twoFaCode);

            return Request.make('/2fa_check', Request.POST, formData).then((res: any) => {
                if (res.authenticated && !res.twoFaInProgress) {
                    this.$loginInit(res, true);
                }
            }).catch((res) => {
                this.$displayErrorMessage(res);
            });
        }

        Vue.prototype.$pushRedirect = function() {
            if (this.$store.state.user.settings.defaultPath !== this.$router.currentRoute.path) {
                this.$router.push(this.$store.state.user.settings.defaultPath);
            }
        };

        Vue.prototype.$loginInit = function(res: User, pushRedirect?: boolean) {
            if (!res.authenticated) {
                this.$store.commit('resetAuth');
                return;
            }
            pushRedirect = !!pushRedirect;

            this.$store.commit('authenticated', true);
            this.$store.commit('user', res);
            this.$store.commit('organizationName', res.relationName ? res.relationName : '');
            if (this.$i18n) {
                this.$i18n.locale = res.locale;
            }
            this.$store.commit('locale', res.locale);
            this.$root.$emit('change-locale', 'change');

            /*if (res.passwordResetOnLogin) {
                this.$store.commit('passwordResetOnLogin', true);
                if (this.$router.currentRoute.name !== 'change-password-login') {
                    this.$router.push({ name: 'change-password-login' });
                    return;
                }
            } else {
                this.$store.commit('passwordResetOnLogin', false);
            }*/


            if (res.settings.orderStop) {
                this.$flashMessage('OrderStop', 'warning', { '{startDate}': res.settings.orderStop.startDate, '{endDate}': res.settings.orderStop.endDate}, false);
            } else {
                this.$flashMessage('LoginMessage', 'success');
            }
            this.$heartbeat();

            if (pushRedirect) {
                this.$pushRedirect();
            }
        };

        Vue.prototype.$impersonate = function (username: string) {
            if (!this.$store.state.user.isSwitched) {
                Request.make('/security/impersonate', Request.GET, { poco_switch_user: username }).then((res) => {
                    this.$store.commit('user', res);
                    this.$flashMessage('LoginImpersonation', 'success', { '{0}': username });
                    this.$pushRedirect();
                    this.$router.go();
                });
            }
        };

        Vue.prototype.$toPrevious = function () {
            if (this.$store.state.user.isSwitched) {
                Request.make('/security/impersonate', Request.GET, { poco_switch_user: '_exit' }).then((res) => {
                    this.$store.commit('user', res);
                    if (location.hostname === 'klanten.postma-cohen.nl') {
                        location.href = 'https://admin.postma-cohen.nl';
                    } else if (location.hostname === 'staging-klanten.postma-cohen.nl') {
                        location.href = 'https://staging-admin.postma-cohen.nl';
                    } else {
                        this.$flashMessage('LoginImpersonation', 'success', { '{0}': res.name });
                        this.$pushRedirect();
                        this.$router.go();
                    }
                });
            }
        };

        Vue.prototype.$logout = function () {
            return Request.make('/logout', Request.GET).then(() => {
                this.$loginReset();

                if (this.$router.currentRoute.path !== '/') {
                    this.$router.push('/');
                }
            });
        };

        Vue.prototype.$loginReset = function() {
            this.$store.commit('authenticated', false);
            this.$store.commit('user', new User());
            this.$store.commit('reset');
        };

        Vue.prototype.$heartbeat = function() {
            if (this.$store.state.authenticated) {
                if (this.heartbeatInterval) {
                    window.clearInterval(this.heartbeatInterval);
                }

                this.heartbeatInterval = window.setInterval(() => {
                    Request.make('/login', Request.GET).then((res: User) => {
                        if (res.id !== this.$store.state.user.id) {
                            this.$loginInit(res);
                            this.$router.go();
                        }
                    }).catch((res) => {
                        this.$logout();
                    });
                }, 60000);
            }
        };

        //Vue.prototype.$checkPasswordResetOnLogin = checkPasswordResetOnLogin;
    }
}
