<template>
    <div ref="accordion" class="accordion accordion-flush">
        <template v-for="item in items">
            <div class="accordion-item" :key="item.value">
                <h2 class="accordion-header" :id="'heading_' + item.value">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse_' + item.value" aria-expanded="false" :aria-controls="'collapse_' + item.value">
                        {{ $t(item.label) }} <span v-if="item.feedback">{{ item.feedback }}</span>
                    </button>
                </h2>
                <div :id="'collapse_' + item.value" class="accordion-collapse collapse" :aria-labelledby="'heading_' + item.value">
                    <div class="accordion-body">
                        <slot :name="'body:' + item.value" :item="item"></slot>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { Collapse } from "bootstrap";
import {Component, Prop, Vue} from 'vue-property-decorator';

export interface AccordionItem {
    label: string;
    value: string;
    feedback: string;
}

@Component({
    inheritAttrs: false,
})
export default class PCAccordion extends Vue {
    @Prop({ default: () => [] })
    readonly items: Array<AccordionItem>;

    created() {
        Vue.nextTick(() => {
            const elm = this.$refs.accordion as HTMLDivElement;
            const collapseElementList = elm.querySelectorAll('.collapse');
            [...collapseElementList].map(collapseEl => new Collapse(collapseEl, {toggle: false}));
        });
    }
}
</script>

<style lang="scss">
.accordion-header > button > span {
    display: inline-block;
    color: #EF3E42;
    font-weight: 400;
    font-size: 16px;
    padding-left: 1em;
}
</style>
