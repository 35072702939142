import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        name: 'packing_slip_list',
        path: '/packing-slips',
        component: () => import(/* webpackChunkName: "packing_slip_list" */ '@/views/packing-slip-list/packing-slip-list.vue'),
        meta: {
            title: 'Packing slips'
        }
    },
]
export default routes;
