import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/budgeting',
        props: true,
        component: () => import(/* webpackChunkName: "budgeting_view" */ '@/views/budgeting/view.vue'),
        children: [
            {
                name: 'budgeting_overview',
                path: '',
                component: () => import(/* webpackChunkName: "budgeting_overview" */ '@/views/budgeting/tabs/list.vue'),
                meta: { title: 'Budgeting per year' },
            },
            {
                name: 'budgeting_settings',
                path: 'settings',
                component: () => import(/* webpackChunkName: "budgeting_settings" */ '@/views/budgeting/tabs/settings.vue'),
                meta: { title: 'Budgeting settings' },
            },
        ],
    },
]
export default routes;
