import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/invoices',
        name: 'invoice_list',
        component: () => import(/* webpackChunkName: "invoice_list" */ '@/views/invoice-list/invoice-list.vue'),
        meta: {
            title: 'Invoice overview'
        }
    },
]
export default routes;
