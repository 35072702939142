import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        name: 'reader_list',
        path: '/readers',
        component: () => import(/* webpackChunkName: "reader_list" */ '@/views/reader-list/reader-list.vue'),
        meta: {
            title: 'Reader list'
        }
    },
]
export default routes;
