<template>
    <div>
        <div class="modal fade" tabindex="-1" :class="{ 'show' : show }" :style="{ 'display': visibility }" @click="backdropOnClick">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" :style="{ 'width': width + 'px', 'max-width': width + 'px' }">
                <div class="modal-content" :style="{ 'background-color': backgroundColor }">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            <slot name="label" />
                        </h5>
                        <button type="button" class="btn-close" aria-label="Close" @click="enabled = false"></button>
                    </div>
                    <div class="modal-body">
                        <slot name="body" />
                    </div>
                    <div class="modal-footer">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade" :class="{ 'show' : show }" :style="{ 'display': visibility }"></div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class PCModal extends Vue {
    @Prop({ default: false })
    readonly value: boolean;

    @Prop({ default: 500 })
    readonly width: number;

    @Prop({ default: '#f5f5f5' })
    readonly backgroundColor: string;

    enabled = false;

    show = false;
    visible = false;

    @Watch('value')
    onValueChange(val: boolean) {
        this.enabled = val;
    }

    @Watch('enabled')
    onEnabledChange(val: boolean) {
        this.$emit('input', val);

        if (val) {
            this.visible = true;
            // Wachten tot de animatie is afgelopen
            window.setTimeout(() => {
                this.show = true;
            }, 150);
        } else {
            this.show = false;
            // Wachten tot de animatie is afgelopen
            window.setTimeout(() => {
                this.visible = false;
                this.$emit('disabled');
            }, 150);
        }
    }

    get visibility() {
        return this.visible ? 'block' : 'none'
    }

    backdropOnClick(e: Event) {
        const target = e.target as HTMLElement;
        if (target.classList.contains('modal')) {
            this.enabled = false
        }
    }
}
</script>
