//import { Vue } from 'vue/types/vue';
import forEach from "lodash/forEach";
import store from "@/store";
import i18n from "@/i18n";

declare module 'vue/types/vue' {
    interface Vue {
        $flashMessage(msg: string, type?: string): void;
    }
}

export default class FlashMessagesPlugin {
    public static install(Vue: any, options: any) {
        Vue.prototype.$flashMessage = function (msg: string, type= 'success', replace?: object, removeAutomatically = true) {
            const alertClass: any = {};
            alertClass['alert-' + type] = true;
            const opts = {
                'class': alertClass,
                'removeAutomatically': removeAutomatically,
            };

            let translated = i18n.t(msg).toString();
            if (replace) {
                forEach(replace, (val, key) => {
                    translated = translated.replace(key, String(val));
                });
            }

            store.commit('flashMessage', { msg: translated, options: opts });
        }
    }
}
