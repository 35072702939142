<template>
    <pc-select :items="locales" v-model="locale" label="Language" @change="changeLocale" />
</template>

<script lang="ts" type="typescript">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import { Request } from "@/lib/apollo"

enum Mode {
    Select = 'select',
    Set = 'set',
}

@Component
export default class PCLocale extends Vue {
    @Prop({ default: '' })
    readonly value: string;

    @Prop({ default: '' })
    readonly relation: string;

    @Prop({ default: Mode.Select })
    readonly mode: Mode;

    locales: Array<any> = [
        { label: 'Nederlands', value: 'nl' },
        { label: 'English', value: 'en' },
    ];
    locale = 'nl';

    created() {
        this.locale = this.value;

        if (!this.locale) {
            this.locale = this.$store.state.locale;
        }
    }

    @Watch('value')
    onValueChange(val: string) {
        this.locale = val;
    }

    @Watch('locale')
    onLocaleChange(val: string) {
        this.$emit('input', val);
    }

    setLocale(locale: string) {
        if (!this.relation) {
            this.$i18n.locale = locale;
            this.$store.commit('locale', locale);
            this.$root.$emit('change-locale', 'change');
        }
    }

    changeLocale(locale: string) {
        if (this.mode !== Mode.Set) {
            return;
        }

        this.setLocale(locale);

        Request.make('/locale/change/' + encodeURIComponent(locale) + (this.relation ? '/' + encodeURIComponent(this.relation) : ''));
    }
}
</script>
