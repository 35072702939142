<template>
    <pc-modal v-model="enabled">
        <template v-slot:label>
            <slot name="label">{{ $t('Confirm') }}</slot>
        </template>
        <template v-slot:body>
            <slot name="body">{{ $t('Are you sure?') }}</slot>
        </template>
        <template v-slot:footer>
            <pc-btn type="button" class="btn-outline-primary" @click="closeModal()" v-html="$t('Cancel')" />
            <pc-btn type="submit" class="btn-primary ms-3" @click="confirm()" v-html="$t('Continue')" />
        </template>
    </pc-modal>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class PCConfirm extends Vue {
    @Prop({ default: false })
    readonly value: boolean;

    enabled = false;

    @Watch('value')
    onValueChange(val: boolean) {
        this.enabled = val;
    }

    @Watch('enabled')
    onEnabledChange(val: boolean) {
        this.$emit('input', val);
    }

    closeModal() {
        this.enabled = false;
    }

    confirm() {
        this.$emit('confirmed');
        this.closeModal();
    }
}
</script>
