<template>
    <form class="needs-validation" novalidate @submit="submitForm">
        <slot />
    </form>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({})
export default class PCForm extends Vue {
    @Prop()
    submit: () => unknown

    submitForm(evt: any) {
        evt.preventDefault();
        evt.stopPropagation();

        const form = evt.target as HTMLFormElement;
        const btn = evt.submitter as HTMLButtonElement|HTMLInputElement;
        if (form.checkValidity()) {
            form.classList.remove('was-validated');

            const formData = new FormData(form);
            if (btn?.name && btn?.value) {
                formData.append(btn.name, btn.value);
            }
            this.$emit('submit', formData);
        } else {
            form.classList.add('was-validated');
            this.$emit('invalid');
        }
    }
}
</script>
