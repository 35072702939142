<template>
    <div class="form-check mb-3">
        <input class="form-check-input" type="radio" v-bind="$attrs" :value="radioValue" :id="labelID" v-model="inputValue" @change="onRadioChange">
        <label class="form-check-label" :for="labelID"> {{ $t(label) }}</label>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
    inheritAttrs: false,
})
export default class PCRadio extends Vue {
    @Prop({ default: '' })
    readonly label: string;

    @Prop({ default: '' })
    readonly value: string;

    @Prop({ default: '' })
    readonly radioValue: string;

    inputValue = '';
    labelID = '';

    mounted () {
        this.inputValue = this.value;
        this.labelID = 'pc-check-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5)
    }

    @Watch('value')
    onValueChange(val: string) {
        this.inputValue = val;
    }

    @Watch('inputValue')
    onRadioChange() {
        this.$emit('input', this.inputValue);
        this.$emit('change', this.inputValue);
    }
}
</script>
