<template>
  <button @click="$emit('click')" class="btn"><slot /></button>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class PCBtn extends Vue {
}
</script>
