interface FormHandlerViolation {
    propertyPath: string;
    message: string;
}
export interface FormHandlerResultObject {
    '@context': string;
    '@id': string;
    '@type': string;
    'hydra:title': string;
    'hydra:description': string;
    violations: FormHandlerViolation[];
    id: string;
}

export default class FormHandler {
    private resultObject = <FormHandlerResultObject> {};

    setResult(obj: FormHandlerResultObject) {
        this.resultObject = obj;
    }

    checkError(field: string) {
        if (this.resultObject && this.resultObject['@type'] === 'ConstraintViolationList') {
            for (const idx in this.resultObject.violations) {
                if (!Object.prototype.hasOwnProperty.call(this.resultObject.violations, idx)) {
                    continue;
                }
                if (this.resultObject.violations[idx].propertyPath === field) {
                    return true;
                }
            }
        }
        return false;
    }

    getErrors(field: string) {
        const output: string[] = [];
        if (this.resultObject && this.resultObject['@type'] === 'ConstraintViolationList') {
            for (const idx in this.resultObject.violations) {
                if (!Object.prototype.hasOwnProperty.call(this.resultObject.violations, idx)) {
                    continue;
                }
                if (this.resultObject.violations[idx].propertyPath === field) {
                    output.push(this.resultObject.violations[idx].message);
                }
            }
        }
        return output;
    }

    getGlobalError() {
        if (this.resultObject && this.resultObject['@type'] === 'hydra:Error') {
            return this.resultObject['hydra:description'];
        }
        return '';
    }
}
