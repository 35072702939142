import { RouteConfig } from 'vue-router';

const routes: Array<RouteConfig> = [
    {
        path: '/management-info',
        props: true,
        component: () => import(/* webpackChunkName: "circulation_list" */ '@/views/management-info/view.vue'),
        children: [
            {
                name: 'management_info_intro',
                path: '',
                component: () => import(/* webpackChunkName: "management_info_intro" */ '@/views/management-info/tabs/intro.vue'),
                meta: { title: 'Financial introduction' },
            },
            {
                name: 'management_info_overview_01',
                path: 'overview_01',
                component: () => import(/* webpackChunkName: "management_info_overview_01" */ '@/views/management-info/tabs/overview01.vue'),
                meta: { title: 'Financial Overview I' },
            },
            {
                name: 'management_info_overview_02',
                path: 'overview_02',
                component: () => import(/* webpackChunkName: "management_info_overview_02" */ '@/views/management-info/tabs/overview02.vue'),
                meta: { title: 'Financial Overview II' },
            },
        ],
    },
]
export default routes;
